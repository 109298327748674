<template>
  <main>
    <section class="horizon">
      <div class="container">
        <div class="in-between">
          <h2 class="horizon__title">Campañas y canales</h2>

        </div>
   
        
        <div class="row row-table">
          <table-data title="Campañas" v-bind:source="mongo + '/' + db + '/'" metodo="campaing" name="cotizaciones" tableTemplate="TableCampaigns" />
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import TableData from '@/components/TableData.vue'

export default {
  name: 'Conversiones',
  components: {
    'table-data': TableData,
  },
  setup() {
    const store = useStore();
    // const analytics = computed(() => store.getters.getAnalyticsWPJSONURL);
    const mongo = computed(() => store.getters.getMongoURL);
    const db = computed(() => store.getters.getDBDashboard);
    // return { analytics, mongo, db }
    return {  mongo, db }
  },
}
</script>
