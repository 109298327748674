<template>
  <article class="card card--full loading">
    <div class="card__body font-centered">
      <h3 class="card__title title-small">
        Título
      </h3>
      <div class="card__table-simulate">
        <p>line 1</p>
        <p>line 2</p>
        <p>line 3</p>
        <p>line 4</p>
        <p>line 5</p>
        <p>line 6</p>
        <p>line 7</p>
        <p>line 8</p>
        <p>line 9</p>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.loading {
  h3,
  h4,
  p {
    color: #e3e3e3 !important;
    background: #e3e3e3 !important;
    border-radius: 5px !important;
  }
  .card {
    &__table {
      &-simulate {
        height: 350px;
        background: #e3e3e3 !important;
        border-radius: 0 !important;
        p {
          color: #eee !important;
          background: #eee !important;
          border-radius: 0 !important;
        }
      }
    }
  }
}
.gr-3.card--big {
  max-width: 25%;
}
</style>
