<template lang="html">
  <component
    :is="tableTemplate"
    v-if="!loading && data"
    :title="title"
    :data="data"
  />
  <TableDataSkeleton v-else />
</template>

<script>
  /* eslint-disable vue/no-dupe-keys */
  /* eslint-disable no-unused-vars */

  import { onMounted, watch, ref, toRefs, computed, provide } from 'vue'
  import { useStore } from 'vuex'
  //Libs
  import axios from 'axios'
  import dataPersistence from '../services/dataPersistence'
  // Components
  import TableDataSkeleton from '@/components/Skeletons/TableDataSkeleton.vue'
  // Templates
  import { TablesViews, FormsViews, rpTemplates } from '@/components/templates/tables'

  export default {
    components: {
      TableDataSkeleton,
      ...TablesViews,
      ...FormsViews,
      ...rpTemplates,
    },
    props: {
      name: String,
      metodo: String,
      title: String,
      source: String,
      dimensionsTag: String,
      match: {
        type: [String, Number],
        default: null,
      },
      unwind: {
        type: String,
        default: null,
      },
      group: {
        type: String,
        default: null,
      },
      limit: {
        type: Number,
        default: null,
      },
      customURL: {
        type: String,
        default: null,
      },
      tableTemplate: String,
    },
    setup(props) {
      const store = useStore()
      // Props - States
      const { name, metodo, title, source, dimensionsTag, tableTemplate, match, unwind, group, limit, customURL } = toRefs(
        props
      )
      const URL = `${source.value}${metodo.value}/${name.value}/`
      const keyStorage = `trafico-${title.value.split(' ').join('-')}-${metodo.value}-${name.value}`
      const data = ref(dataPersistence.getItem(keyStorage) || null)
      const loading = ref(false)
      const matchData = ref(match.value)

      // Computed
      const fechaReporte = computed(() => store.getters.getFechaReporte)

      const payload = computed(() => {
        let result = { ...fechaReporte.value }
        if (matchData.value != null) {
          result = { ...result, ...{ match: matchData.value } }
        }
        if (unwind.value != null) {
          result = { ...result, ...{ unwind: unwind.value } }
        }
        if (group.value != null) {
          result = { ...result, ...{ group: group.value } }
        }
        if (limit.value != null) {
          result = { ...result, ...{ limit: limit.value } }
        }
        return result
      })

      // Methods
      /*const fetchData = () => {
        axios
          .post(customURL.value != null ? customURL.value : URL, payload.value)
          .then(response => {
            data.value = response.data
            dataPersistence.setItem(keyStorage, response.data)
            loading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }*/

      const fetchDataApi = () => {
        axios
          .post(URL, {...fechaReporte.value, dimensionsTag: dimensionsTag.value,})
          .then(response => {
            data.value = response.data
            dataPersistence.setItem(keyStorage, response.data)
            loading.value = false
          })
          .catch(error => {
            console.log(error)
          })
      }

      const updateMatchData = value => {
        matchData.value = value
      }

      // Mounted
      onMounted(() => {
        if (!data.value) {
          loading.value = true
          //fetchData()
          fetchDataApi()
        }
      })

      // Watcher's
      watch(fechaReporte, () => {
        loading.value = true
        //fetchData()
        fetchDataApi()
      })

      watch(matchData, () => {
        loading.value = true
        //fetchData()
        fetchDataApi()
      })

      //Provide
      provide('matchData', matchData)
      provide('updateMatchData', updateMatchData)

      return { data, title, loading, tableTemplate }
    },
  }
</script>

<style lang="scss" scoped>
  .loading {
    h3,
    h4,
    p {
      color: #e3e3e3 !important;
      background: #e3e3e3 !important;
      border-radius: 5px !important;
    }
    .card {
      &__table {
        &-simulate {
          height: 350px;
          background: #e3e3e3 !important;
          border-radius: 0 !important;
          p {
            color: #eee !important;
            background: #eee !important;
            border-radius: 0 !important;
          }
        }
      }
    }
  }
  .gr-3.card--big {
    max-width: 25%;
  }
</style>
